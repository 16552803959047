<template>
  <div>
    <solution-header
        :body="body"
        :image="image"
        :show-buy-btn="showBuyBtn"
        :show-detail-btn="showDetailBtn"
        :title="title"
    />
<!--    <ImageList :images="images"></ImageList>-->
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/map.svg`)"
               :title="`行业背景和业务痛点`"
               :en_title="`Industry Background & Business Pain Points`"></Paragraph>
    <Logotitlebox :datalist="datalistx"></Logotitlebox>

    <Paragraph :imgurl="require(`@/assets/image/contentsvg/db.svg`)"
               :title="`方案优势与服务价值`"
               :en_title="`Industry Background & Business Pain Points`"></Paragraph>
    <div style="width: 70%;margin-left: 15%">
    <Lettertitleblock :datalist="datalistx3"
                      style="display: flex;flex-direction: row;justify-content:center ;
                        width: 100%"></Lettertitleblock>
    <Lettertitleblock :datalist="datalistx4"
                      style="display: flex;flex-direction: row;justify-content:center ;
                        width: 100%"></Lettertitleblock>
    </div>
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/note.svg`)"
               :title="`应用场景`"
               :en_title="`Application Scenarios`"></Paragraph>
    <div style="width: 50%;margin-left: 25%">
      <Lettertitleblock :datalist="datalistx5"
                        style="display: flex;flex-direction: row;justify-content:center ;
                        width: 100%"></Lettertitleblock>
    </div>
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/note.svg`)"
               :title="`案例`"
               :en_title="`Cases`"></Paragraph>
    <div style="width: 70%;display: flex;flex-direction: row;margin-left: 15%;
    justify-content: center;">
      <div style="font-size:1.8rem;padding: 2rem;font-weight: bold;
        display: flex;flex-direction: column;align-items: center">
        <img src="@/assets/image/solution/supply/page3.png" style="height: 26rem;;padding: 2rem"/>
        农产品范围溯源
      </div>
      <div style="font-size:1.8rem;padding: 2rem;font-weight: bold;
        display: flex;flex-direction: column;align-items: center">
        <img src="@/assets/image/solution/supply/page1.png" style="height: 26rem;padding: 2rem"/>
        汽车零配件防伪溯源
      </div>
      <div style="font-size:1.8rem;padding: 2rem;font-weight: bold;
        display: flex;flex-direction: column;align-items: center">
        <img src="@/assets/image/solution/supply/page2.png" style="height: 26rem;;padding: 2rem"/>
        跨境贸易
      </div>
    </div>
  </div>
</template>

<script>
// import ImageList from '@/components/common/ImageList';
import SolutionHeader from '@/components/common/SolutionHeader.vue';
import Paragraph from '@/components/chain/basic/common/paragraph';
import Logotitlebox from '@/components/chain/basic/common/logotitlebox';
import Lettertitleblock from '@/components/chain/basic/common/lettertitleblock';

export default {
  name: 'BaaSLeaflet',
  components: {
    // ImageList,
    SolutionHeader,
    Paragraph,
    Logotitlebox,
    Lettertitleblock,
  },
  data() {
    return {
      datalistx5: [
        {
          title: '防伪溯源\n\n\n\n',
          imgsrc: 'A',
          list: [
            '农产品、汽车零配件等的',
            '全流程溯源和防伪',
            '',
          ],
        }, {
          title: '跨境贸易\n\n\n',
          imgsrc: 'B',
          list: [
            '跨境订单交易、跨境贸易物流追踪、',
            '跨境贸易业务协同等',
            '',
          ],
        },
      ],
      datalistx4: [
        {
          title: '数据征信\n\n\n',
          imgsrc: 'D',
          list: [
            '积累生产和流通全过程可信数据',
            '并以数据为背书完善征信机制',
            '获取金融保险支持',
          ],
        }, {
          title: '供应链管理与协同\n\n',
          imgsrc: 'E',
          list: [
            '供应链上下游相关主体的数据都存储在链上',
            '各环节可高效共享数据',
            '提高供应链管理和协同效率，降低协同成本',
            '',
          ],
        }, {
          title: '数据流通与变现\n\n\n',
          imgsrc: 'F',
          list: [
            '以智能合约帮助企业完成数字资产的',
            '流通与变现',
            '',
          ],
        },
      ],
      datalistx3: [
        {
          title: '防伪防窜货\n\n',
          imgsrc: 'A',
          list: [
            '特制标签写入产品生产',
            '流通全程信息，保证真实性',
            '随时跟踪产品流向，实时查看经销商窜货情况',
            '足不出户也能对窜货情况一览无余',
            '',
          ],
        }, {
          title: '通证营销\n\n',
          imgsrc: 'B',
          list: [
            '可设计通证模型',
            '鼓励消费者通过扫码、注册、分享转发等',
            '获取通证，并以此兑换折扣、返现等优惠',
            '还可以打通电商平台多种商品消费',
            '提升品牌知名度和销量',
            '',
          ],
        }, {
          title: '精准营销\n',
          imgsrc: 'C',
          list: [
            '通过消费者溯源可直接触及消费者',
            '经消费者授权可获取消费者个性化数据',
            '开展精准研发、以销定产以及精准营销',
            '',
          ],
        },
      ],
      datalistx: [
        {
          imageurl: require('@/assets/image/contentsvg/setting.svg'),
          list: [
            '传统的供应链管理系统是',
            '中心化系统，数据易被篡改',
            '缺乏公信力',
          ],
        }, {
          imageurl: require('@/assets/image/contentsvg/house.svg'),
          list: [
            '信息孤岛化严重',
            '各类数据无法形成有价值信息',
            '上下游主体间协同成本高',
            '',
          ],
        }, {
          imageurl: require('@/assets/image/contentsvg/book.svg'),
          list: [
            '传统溯源标签容易被篡改',
            '无法真正防伪目的',
            '',
          ],
        }, {
          imageurl: require('@/assets/image/contentsvg/tie.svg'),
          list: [
            '没有有效的数据变现机制',
            '数据生产者贡献数据的积极性低',
            '',
          ],
        },
      ],
      title: '区块链+供应链管理解决方案',
      body:
          '基于区块链的供应链管理平台，利用特有的量子云码技术与区块链技术相结合，'
          + '收集生产流通全程中的数据上链，确保数据真实不可篡改，实现一物一码的全流程可信追溯，'
          + '并在保证隐私的前提下实现产业数据高效共享与价值流通，实现数据商业价值。',
      image: require('@/assets/image/solution/supply/supply.svg'),
      showDetailBtn: true,
      showBuyBtn: true,
      images: [
        require('@/assets/image/solution/supply/27_01.jpg'),
        require('@/assets/image/solution/supply/27_02.jpg'),
        require('@/assets/image/solution/supply/27_03.jpg'),
        require('@/assets/image/solution/supply/27_04.jpg'),
      ],
    };
  },
};
</script>

<style scoped>
</style>
